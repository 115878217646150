import React, { Suspense, lazy } from "react";
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { Provider } from "react-redux";
import fotterLogo from "./assets/images/footerLogo.png"
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./store";

const AppRouter = lazy(() => import('./routes'))
const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div style={{ textAlign: "center", minHeight: '100vh', display: 'grid', placeItems: 'center' }}>
        <div className="d-flex flex-column">
          <img src={fotterLogo} alt="logo" height={100} />
          loading...
        </div>
      </div>}>
        <PersistGate persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Suspense>
    </Provider>
  )
}
export default App