import { combineReducers } from "redux";
import patient from "./patient";
import provider from "./provider";
import meeting from "./meeting";
import shop from "./shop";
const root = combineReducers({
    patient,
    provider,
    meeting,
    shop,
});
export default root;