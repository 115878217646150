export const LOGIN = "LOGIN"
export const INVOICE_DATA = "INVOICE_DATA"

export const MEETING_CHANNEL = "MEETING_CHANNEL"
export const MEETING_TOKEN = "MEETING_TOKEN"
export const STREAM_USERS = "STREAM_USERS"
export const IN_MEETING = "IN_MEETING"
export const IS_STREAM_MINIMIZE = "IS_STREAM_MINIMIZE"
export const CART_PRODUCTS = "CART_PRODUCTS"
export const CART_PRODUCTS_SUMMERY = "CART_PRODUCTS_SUMMERY"
export const CART_USER_SUMMERY = "CART_USER_SUMMERY"