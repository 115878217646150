import { LOGIN } from "../action/types";

const INIT_STATE = {
    login_success: null,

};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOGIN:
            if (payload?.status) {
                return {
                    ...state,
                    login_success: payload,
                };
            }
        default:
            return state;
    }
};