// reducers/shop.js

import { CART_PRODUCTS ,CART_PRODUCTS_SUMMERY ,CART_USER_SUMMERY} from "../action/types";

const INIT_STATE = {
    productArray: [],
    summeryArray: null,
    userShipping: null,
};

export default (state = INIT_STATE, action) => {    
    const { type, payload } = action;
    switch (type) {
        case CART_PRODUCTS:
            return {
                ...state,
                productArray: payload, // Append new item to the existing array
            };       
        case CART_PRODUCTS_SUMMERY:
            return {
                ...state,
                summeryArray: payload, // Append new item to the existing array
            };       
        case CART_USER_SUMMERY:
            return {
                ...state,
                userShipping: payload, // Append new item to the existing array
            };       
        default:
            return state;
    }
};
